import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import Layout from "../../components/layout"
import Text from "../../components/text"
import {
  Input,
  Loading,
  CSelect,
  TextArea,
  Button,
} from "../../components/form"
import { space } from "../../theme"

const Wrapper = styled.div`
  display: grid;
`

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${space("normal")};
  margin: 0 auto;
  padding: ${space("large")} 0;
  -webkit-font-smoothing: antialiased;
  ${up("tablet")} {
    grid-template-columns: 768px;
  }
  ${up("desktop")} {
    grid-template-columns: 960px;
  }
`

const F = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  justify-items: flex-start;
`

const SubmitBtn = styled(Button)`
  justify-self: flex-end;
`

const getData = (target: HTMLFormElement) => ({
  type: "securityIncident",
  firstname: target.firstname.value,
  lastname: target.lastname.value,
  email: target.email.value,
  merchant_name: target.merchant_name.value,
  partner_name: target.partner_name.value,
  phonenumber: target.phonenumber.value,
  securtytype: target.securtytype.value,
  message: target.message.value,
})

const options = [
  { value: "loststolen", label: "Lost or stolen account details" },
  { value: "takeover", label: "Account Takeover" },
  { value: "other", label: "Other" },
]

const SecurityIncident = () => {
  const [loading, setLoading] = React.useState(false)
  const onsubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const data = getData(e.currentTarget)
    setLoading(true)
    fetch("/api/form", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then(r => r.json())
      .then(json => {
        setLoading(false)
      })
    e.preventDefault()
  }
  return (
    <Layout>
      <Wrapper>
        <Container>
          <Text type="headline" weight="bold" align="center">
            Security Incident Form
          </Text>
          <Text type="h4" align="center" color="grey">
            We take security incidents seriously. While we are reviewing your
            notification, we will disable your account to prevent any funds from
            leaving the system. If you were permitted to do so, you will still
            be able to receive transactions during this time.
          </Text>
          <F onSubmit={onsubmit}>
            <input type="hidden" name="type" value="securityIncident" />
            <Input
              type="text"
              name="firstname"
              required={true}
              placeholder="First name (required)"
            />
            <Input
              type="text"
              name="lastname"
              required={true}
              placeholder="Last name (required)"
            />
            <Input
              type="text"
              name="email"
              required={true}
              placeholder="Email address (required)"
            />
            <Input
              type="text"
              name="merchant_name"
              placeholder="Merchant name (if applicable)"
            />
            <Input
              type="text"
              name="partner_name"
              placeholder="Partner name (if applicable)"
            />
            <Input type="text" name="phonenumber" placeholder="Phone number" />
            <CSelect
              name="securtytype"
              defaultValue={options[0]}
              options={options}
            />
            <TextArea
              name="message"
              placeholder="Additional details"
              rows={10}
            ></TextArea>
            <SubmitBtn disabled={loading} type="submit">
              {loading ? <Loading /> : "Send"}
            </SubmitBtn>
          </F>
        </Container>
      </Wrapper>
    </Layout>
  )
}
export default SecurityIncident
